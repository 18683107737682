import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard {
  private isPermitted = true;

  canActivate(): boolean {
    return this.isPermitted;
  }

  public setPermission(isPermitted: boolean) {
    this.isPermitted = isPermitted;
  }
}
