export enum UnfallRenteMinMaxEnum {
  MIN = 250,
  MAX = 2500,
}

export enum GrundsummeMin {
  PROGRESSION_OHNE = 30000,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROGRESSION_1000 = 30000,
  PROGRESSION_225 = 40000,
}

export enum GrundsummeMax_Progression_OHNE {
  GFG_S = 250000,
}

export enum GrundsummeMax_Progression_225 {
  GFG_K = 250000,
  GFG_A_B = 350000,
}

export enum GrundsummeMax_Progression_1000 {
  GFG_K = 125000,
  GFG_A_B = 250000,
}
