import { KeycloakConfig } from "keycloak-js";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export interface IEnvironment {
  environment: string;
  keycloakConfig: KeycloakConfig;
  backendUrl: string;
  umstellungsfaehigkeitsAbschaltungIsErlaubt: boolean;
}

export abstract class DynamicEnvironment implements IEnvironment {
  public get environment(): string {
    return window.config.environment;
  }

  public get umstellungsfaehigkeitsAbschaltungIsErlaubt(): boolean {
    return window.config.umstellungsfaehigkeitsAbschaltungIsErlaubt;
  }

  public get keycloakConfig(): KeycloakConfig {
    return {
      url: window.config.keycloak.url,
      realm: window.config.keycloak.realm,
      clientId: window.config.keycloak.clientId,
    };
  }

  public get backendUrl(): string {
    return window.config.backendUrl;
  }

  public get zobUrl(): string {
    return window.config.zobUrl;
  }
}
