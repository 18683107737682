import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PermissionGuard } from "../../permission.guard";
import { Subject } from "rxjs";
import { ErrorResponse } from "../../dashboard/models/errorResponse";
import { KeycloakService } from "./keycloak.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  private isAlertVisible = new Subject<ErrorData>();
  private errorMessageRoute = "/fehlermeldung";
  private defaultErrorTitle = "Es ist ein Fehler aufgetreten";

  constructor(
    private router: Router,
    private permissionGuard: PermissionGuard,
    private keycloakService: KeycloakService,
  ) {}

  public handleError(error: ErrorResponse) {
    if (error.status === 403) {
      this.permissionGuard.setPermission(false);
      this.router.navigate([this.errorMessageRoute, { status: 403, detail: error.detail }]);
    } else if (error.status === 409 && error.detail === EinwilligungserklaerungErrorMessage.TITLE) {
      // Ausnahmeregelung für EWE Versand. Erst nach 14 Tagen ist Neuversand möglich, sonst 409
      error.title = error.detail;
      error.detail = EinwilligungserklaerungErrorMessage.DETAIL;
      error.status = null;
      this.sendMessage(error);
    } else if (error.status === 409 && error.detail === "Statusaenderung auf VERWORFEN bereits erfolgt.") {
      // no error message should show
      return;
    } else {
      error.title = this.defaultErrorTitle;
      this.sendMessage(error);
    }
  }

  public clearMessage() {
    this.isAlertVisible.next({
      visibility: false,
      title: null,
      status: null,
      detail: null,
    });
  }

  public getIsAlertVisible(): Subject<ErrorData> {
    return this.isAlertVisible;
  }

  private sendMessage(error: ErrorResponse) {
    if (!error.status) {
      this.isAlertVisible.next({
        visibility: true,
        title: error.title,
        detail: error.detail ?? "",
      });
    } else {
      this.isAlertVisible.next({
        visibility: true,
        title: error.title,
        status: error.status,
        detail: error.detail ?? "",
      });
    }
  }
}

export interface ErrorData {
  visibility: boolean;
  title: string;
  status?: number;
  detail: string;
}

export enum EinwilligungserklaerungErrorMessage {
  TITLE = "Einwilligungserklärung wurde bereits versand!",
  DETAIL = "Die E-Mail zur Bestätigung der Einwilligungserklärung kann pro E-Mail-Adresse nur alle 14 Tage versendet werden, solange die Einwilligungserklärung nicht bearbeitet wurde.",
}
