// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-header .error-icon {
  margin-bottom: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/error-page/error-page.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".error-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  .error-icon {\n    margin-bottom: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
