/**
 * suh-bestandsmanagement
 * Mittels dieser Schnittstelle wird der Bestand der Sach-, Unfall und Haftpflichtverträge gepflegt und verwaltet.
 */
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, Subject, timer } from "rxjs";
import { PaginatedVertragsumstellung, UmstellungsfaehigkeitEnum } from "../../dashboard/models/models";
import { KeycloakService } from "./keycloak.service";
import { catchError, map, mergeMap, share, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { EnvironmentService } from "./environment.service";
import { Vertragsumstellungsfaehigkeit } from "../../dashboard/models/vertragsumstellungsfaehigkeit";
import { HttpHelperService } from "./http-helper.service";
import { VertragsdatenFuerAngebot } from "../models/vertragsdaten-fuer-angebot";
import { Anschreiben } from "../models/anschreiben.model";
import { AenderbareVertragsdatenReise } from "../../dashboard/models/aenderbareVertragsdatenReise";
import { AenderbareVertragsdatenHausrat } from "../../dashboard/models/aenderbareVertragsdatenHausrat";

@Injectable({
  providedIn: "root",
})
export class VertragsumstellungenService implements OnDestroy {
  backendBasePath: string;
  private isUmstellungsFaehigkeitAktiv = false;
  private readonly MIME_TYPE_JSON = "application/json";
  private loadedAnschreiben: Anschreiben;
  private stopPolling = new Subject();

  constructor(
    protected httpClient: HttpClient,
    private keycloak: KeycloakService,
    private environment: EnvironmentService,
    private httpHelper: HttpHelperService,
  ) {
    this.backendBasePath = this.environment.getBackendUrl();
  }

  ngOnDestroy() {
    this.stopPolling.next(undefined);
  }

  /**
   * Schnittstelle zur Ermittlung der Vertragsumstellungen für den angemeldeten Vermögensberater
   */
  public getVertragsumstellungen(): Observable<PaginatedVertragsumstellung> {
    return this.keycloak.getVbNummer().pipe(
      mergeMap((vbNummer) => {
        if (vbNummer === null || vbNummer === undefined) {
          throw new Error("Required parameter vbNummer was null or undefined when calling getVertragsumstellungen.");
        }
        let queryParameters = this.httpHelper.newHttpParams();
        if (vbNummer) {
          queryParameters = this.httpHelper.addToHttpParams(queryParameters, vbNummer, "vbNummer");
        }
        const headers = this.httpHelper.newHttpHeader("Accept", this.MIME_TYPE_JSON);

        const responseType = "json";

        // timer for polling; request new data every 60 minutes
        return timer(1, 3600000).pipe(
          switchMap(() => {
            return this.httpClient.get<PaginatedVertragsumstellung>(`${this.backendBasePath}/vertragsumstellungen`, {
              params: queryParameters,
              responseType,
              headers,
            });
          }),
          // every subscriber shares the same observable
          share(),
          // take until service is destroyed to prevent memory leak
          takeUntil(this.stopPolling),
        );
      }),
    );
  }

  public setUmstellungsfaehigkeit(
    vertragsnummer: string,
    bestandsvertragUmstellungsfaehigkeit: UmstellungsfaehigkeitEnum,
  ): Observable<Vertragsumstellungsfaehigkeit> {
    if (this.environment.getUmstellungsfaehigkeitsAbschaltungIsErlaubt()) {
      return this.isUmstellungsFaehigkeitAktiv
        ? this.updateUmstellungsfaehigkeit(vertragsnummer)
        : of({
            umstellungsfaehigkeit: bestandsvertragUmstellungsfaehigkeit,
            umstellungshinweise: [],
          });
    } else {
      return this.updateUmstellungsfaehigkeit(vertragsnummer);
    }
  }

  setUmstellungsstatusToVerworfen(vertragsnummer: string): Observable<EmptyResponse> {
    return this.httpClient.post(`${this.environment.getBackendUrl()}/vertragsumstellungen/verworfeneumstellungen`, {
      vertragsnummer,
    });
  }

  setUmstellungsstatusToOffen(vertragsnummer: string): Observable<EmptyResponse> {
    return this.httpClient.post(`${this.environment.getBackendUrl()}/vertragsumstellungen/offeneumstellungen`, {
      vertragsnummer,
    });
  }

  getVersandAbbruchMoeglich(vertragsnummer: string): Observable<boolean> {
    return this.httpClient
      .get<{
        versandAbbruchMoeglich: boolean;
      }>(`${this.environment.getBackendUrl()}/vertragsumstellungen/${vertragsnummer}/versandabbruchpruefung`)
      .pipe(map((result) => result.versandAbbruchMoeglich as boolean));
  }

  setUmstellungsstatusToVersendet(angebotDaten: VertragsdatenFuerAngebot): Observable<EmptyResponse> {
    return this.keycloak.getMandant().pipe(
      take(1),
      mergeMap((mandant) => {
        const httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        };
        const body = { mandant, ...angebotDaten };
        return this.httpClient.post(
          `${this.environment.getBackendUrl()}/vertragsumstellungen/${angebotDaten.vertragsnummer}/versendeteangebote`,
          body,
          httpOptions,
        );
      }),
    );
  }

  getisUmstellungsFaehigkeitAktiv(): boolean {
    return this.isUmstellungsFaehigkeitAktiv;
  }

  setisUmstellungsFaehigkeitAktiv(aktiv: boolean) {
    this.isUmstellungsFaehigkeitAktiv = aktiv;
  }

  getPostAnschreiben(
    vertragnummer: string,
    aenderbareVertragsdatenReise: AenderbareVertragsdatenReise,
    aenderbareVertragsdatenHausrat: AenderbareVertragsdatenHausrat,
  ): Observable<Anschreiben> {
    return this.keycloak.getMandant().pipe(
      mergeMap((mandant) => {
        return this.httpClient
          .post(`${this.environment.getBackendUrl()}/vertragsumstellungen/${vertragnummer}/anschreiben`, {
            mandant,
            reise: aenderbareVertragsdatenReise?.reise,
            reisegepaeck: aenderbareVertragsdatenReise?.reisegepaeck,
            hausratOptimalXxl: aenderbareVertragsdatenHausrat?.hausratOptimalXxl,
          })
          .pipe(
            map((res) => res as Anschreiben),
            tap((res) => {
              this.loadedAnschreiben = res;
            }),
          );
      }),
    );
  }

  fetchAnschreibenVorschauPdf(vertragsdatenFuerAngebot: VertragsdatenFuerAngebot): Observable<PdfResponse> {
    return this.keycloak.getMandant().pipe(
      mergeMap((mandant) => {
        vertragsdatenFuerAngebot.mandant = mandant;
        return this.fetchPdf("vorschauen", { mandant, ...vertragsdatenFuerAngebot });
      }),
    );
  }

  fetchLeistungsvergleichPdf(vertragsdatenFuerAngebot: VertragsdatenFuerAngebot): Observable<PdfResponse> {
    return this.keycloak.getMandant().pipe(
      mergeMap((mandant) => {
        vertragsdatenFuerAngebot.mandant = mandant;
        return this.fetchPdf("leistungsvergleiche", vertragsdatenFuerAngebot);
      }),
    );
  }

  getAnschreiben(): Anschreiben {
    return this.loadedAnschreiben ?? { bestandteile: [] };
  }

  private fetchPdf(
    path: "leistungsvergleiche" | "vorschauen",
    vertragsdatenFuerAngebot: VertragsdatenFuerAngebot,
  ): Observable<PdfResponse> {
    // Safari doesn't support opening windows in async functions, so we open it here and set location later
    const openWindow: Window = window.open("pdfSpinner");
    const { vertragsnummer, ...restDatenFuerAngebot } = vertragsdatenFuerAngebot;
    return this.httpClient
      .post(`${this.environment.getBackendUrl()}/vertragsumstellungen/${vertragsnummer}/${path}`, {
        ...restDatenFuerAngebot,
      })
      .pipe(
        take(1),
        catchError((err) => {
          openWindow.close();
          throw err;
        }),
        map((pdfResponse) => pdfResponse as PdfResponse),
        tap((pdfResponse) => this.handlePdfData(pdfResponse.dokument, openWindow)),
      );
  }

  private updateUmstellungsfaehigkeit(vertragsnummer: string): Observable<Vertragsumstellungsfaehigkeit> {
    return this.httpClient.post<Vertragsumstellungsfaehigkeit>(
      `${this.environment.getBackendUrl()}/vertraege/${vertragsnummer}/umstellungsfaehigkeitsaktualisierungen`,
      vertragsnummer,
    );
  }

  private handlePdfData(pdfBase64: string, openWindow: Window) {
    const pdfBlob = this.base64ToBlob(pdfBase64);
    const data = window.URL.createObjectURL(pdfBlob);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore according to MDN location can be assigned a DOMString, but apparently TS doesn't know that type https://developer.mozilla.org/en-US/docs/Web/API/Window/location
    openWindow.location = data;
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  private base64ToBlob(base64String: string): Blob {
    // inspired by https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    const binaryString = window.atob(base64String.replace(/\s/g, ""));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes.buffer], { type: "application/pdf" });
  }
}

export class PdfResponse {
  dokument: string;
}

export class EmptyResponse {}
