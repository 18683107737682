import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { map } from "rxjs/operators";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  public init() {
    return from(fetch("assets/config/config.json").then((response) => response.json()))
      .pipe(
        map((config) => {
          window.config = config;
          return config;
        }),
      )
      .toPromise();
  }
}
