import { Component, OnInit, ViewChild } from "@angular/core";
import { ErrorData, ErrorHandlerService } from "../services/error-handler.service";
import { DsAlert } from "@dvag/design-system-angular-old";

@Component({
  selector: "app-error-alert",
  templateUrl: "./error-alert.component.html",
  styleUrls: ["./error-alert.component.scss"],
})
export class ErrorAlertComponent implements OnInit {
  @ViewChild("errorAlert") errorAlert: DsAlert;
  errorTitle: string;
  errorMessage: string;

  constructor(private errorHandlerService: ErrorHandlerService) {}

  ngOnInit(): void {
    const isAlertVisible = this.errorHandlerService.getIsAlertVisible();
    isAlertVisible.subscribe((errorData: ErrorData) => {
      this.errorTitle = errorData.title;
      this.errorMessage = errorData.detail;
      this.errorAlert.visible = errorData.visibility;
    });
  }

  public clearErrorMessage() {
    this.errorHandlerService.clearMessage();
  }
}
