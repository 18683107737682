import { Injectable } from "@angular/core";
import { EnvironmentService } from "./environment.service";
import { HttpClient } from "@angular/common/http";
import { ZobUser } from "../models/zob-user.model";
import { MandantEnum } from "../models/mandant.enum";
import { Benutzertyp } from "../models/benutzertyp.enum";
import { map, take, tap } from "rxjs/operators";
import { AssistentService } from "./assistent.service";
import { Observable } from "rxjs";
import { Vermoegensberater } from "../models/vermoegensberater.model";
import { ZobUserRaw } from "../models/zob-user-raw.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  zobUser: ZobUser;

  constructor(
    private assistentService: AssistentService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) {}

  public canSendEwe(): boolean {
    return this.isHauptnutzer() || this.isMitbenutzer() || this.isAssistent() || this.isVertreter();
  }

  public fetchZobContextUser() {
    return this.assistentService.getHauptbenutzerData(this.zobUser.stammdat.vorges).pipe(take(1)).subscribe();
  }

  public fetchZobUser(): Observable<ZobUser> {
    return this.http.get<ZobUserRaw>(`${this.environmentService.getZobUrl()}/ressources/users/loggedinuser`).pipe(
      take(1),
      map((zobUser) => this.mapGesellschaft(zobUser)),
      tap((zobUser) => (this.zobUser = zobUser)),
    );
  }

  public getMandant(): MandantEnum {
    return this.zobUser.gesellschaft;
  }

  /**
   * Returns Vermoegensberater Object of logged in user or of context user if logged in user is not Hauptnutzer
   */
  public getUserOrContextUserData(): Vermoegensberater {
    if (this.isHauptnutzer()) {
      return this.mapZobUserToVermoegensberater(this.zobUser);
    } else {
      return this.mapZobUserToVermoegensberater(this.getZobContextUser());
    }
  }

  public getVbNummer(): string {
    return this.zobUser.userid;
  }

  public getZobContextUser(): ZobUser {
    return this.assistentService.getZobContextUser();
  }

  public getZobUser(): ZobUser {
    return this.zobUser;
  }

  public isAssistent(): boolean {
    return this.zobUser.benutzertyp === Benutzertyp.ASSISTENT;
  }

  public isHauptnutzer(): boolean {
    return this.zobUser.benutzertyp === Benutzertyp.AUSSENDIENST && this.assistentService.getIsEigenerBestand();
  }

  public isMitbenutzer(): boolean {
    return this.zobUser.benutzertyp === Benutzertyp.MITBENUTZER;
  }

  public isVertreter(): boolean {
    return this.zobUser.benutzertyp === Benutzertyp.AUSSENDIENST && !this.assistentService.getIsEigenerBestand();
  }

  private mapZobUserToVermoegensberater(zobUser: ZobUser): Vermoegensberater {
    return {
      vorname: zobUser.vorname,
      nachname: zobUser.name,
      nummer: zobUser.userid,
      email: zobUser.stammdat.email,
      mandant: zobUser.gesellschaft,
    };
  }

  private mapGesellschaft(zobUserRaw: ZobUserRaw): ZobUser {
    switch (zobUserRaw.gesellschaft) {
      case "1":
      case "2":
        zobUserRaw.gesellschaft = MandantEnum.DVAG;
        break;
      case "3":
        zobUserRaw.gesellschaft = MandantEnum.ALLFINANZ;
        break;
      case "5":
        zobUserRaw.gesellschaft = MandantEnum.ALLFINANZ_AG;
        break;
      default:
        zobUserRaw.gesellschaft = MandantEnum.DVAG;
    }

    return zobUserRaw as ZobUser;
  }
}
