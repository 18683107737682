// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error-message-title ds-icon {
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/error-alert/error-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".error-message-title {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  ds-icon {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
