// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  z-index: 998;
}
.modal-wrapper.close {
  display: none;
}
.modal-wrapper .modal {
  overflow: hidden;
  width: 90vw;
  height: 85vh;
  background: white;
  border-radius: 6px;
}
.modal-wrapper .modal .modal-header {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #ebebeb;
}
.modal-wrapper .modal .modal-header ds-icon {
  cursor: pointer;
}
.modal-wrapper .modal .modal-content {
  width: 90vw;
  height: 85vh;
  overflow-y: scroll;
  padding: 20px;
}
.modal-wrapper .modal .modal-content ng-content {
  height: 100%;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modal/modal.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAFJ;AAII;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAMI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;AAJN;AAMM;EACE,YAAA;EACA,kBAAA;AAJR","sourcesContent":["@import \"../../../styles/variables\";\n\n.modal-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow: hidden;\n  width: 100vw;\n  height: 100%;\n  z-index: 998;\n\n  &.close {\n    display: none;\n  }\n\n  .modal {\n    overflow: hidden;\n    width: 90vw;\n    height: 85vh;\n    background: white;\n    border-radius: 6px;\n\n    .modal-header {\n      padding: 20px;\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      border-bottom: 2px solid $DVAG-color-hellgrau-90;\n\n      ds-icon {\n        cursor: pointer;\n      }\n    }\n\n    .modal-content {\n      width: 90vw;\n      height: 85vh;\n      overflow-y: scroll;\n      padding: 20px;\n\n      ng-content {\n        height: 100%;\n        overflow-y: scroll;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
