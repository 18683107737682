import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DsAlert, DsModal } from "@dvag/design-system-angular-old";
import { FormsService } from "../shared/services/forms.service";
import { VertragDetails } from "../dashboard/models/vertragdetails.model";
import {
  AenderbareVertragsdatenUnfall,
  Angebotsart,
  VersichertePerson,
} from "../dashboard/models/aenderbareVertragsdatenUnfall";
import { DeckungsauspraegungEnum } from "../dashboard/models/deckungsauspraegungEnum";
import { UnfallRenteMinMaxEnum } from "../dashboard/models/grundsumme_unfallRente.enums";
import { AngebotBearbeitenService } from "./services/angebot-bearbeiten.service";
import { BeitragErmittelnService } from "../shared/services/beitrag-ermitteln.service";
import { VertragdetailsService } from "../shared/services/vertragdetails.service";
import { FormcontrolsEnum } from "../dashboard/models/formcontrols.enum";
import {
  VersicherungssummeFreizeitPlusEnum,
  VersicherungssummeSeniorenheimEnum,
  VersicherungssummeZweitwohnungEnum,
} from "../dashboard/models/aenderbareVertragsdatenHausrat";
import {
  VersicherungssummeReisegepaeckEnum,
  VersicherungssummeReiseruecktrittEnum,
} from "../dashboard/models/aenderbareVertragsdatenReise";
import { GrundflaecheEnum } from "../dashboard/models/aenderbareVertragsdatenGlas";

@Component({
  selector: "app-angebot-bearbeiten",
  templateUrl: "./angebot-bearbeiten.component.html",
  styleUrls: ["./angebot-bearbeiten.component.scss"],
})
export class AngebotBearbeitenComponent implements OnInit {
  @ViewChild("tableModal") tableModal: DsModal;
  @ViewChild("formAlert") formAlert: DsAlert;
  @ViewChild("validatorAlert") validatorAlert: DsAlert;
  public formControls = FormcontrolsEnum;
  public bearbeitenForm: UntypedFormGroup;
  public unfallFormArray: UntypedFormArray;
  public selectedUnfallIndex = 0;
  public angebotsartEnum = Angebotsart;
  public vertragDetails: VertragDetails;
  public deckungsauspraegungsEnum = DeckungsauspraegungEnum;

  public selectedUnfallEntry: AenderbareVertragsdatenUnfall;
  public modalDropDownEntries = {
    unfallRentenvariante: [
      { label: "ab 50% 1x, 75% 2x", value: "ab 50% 1x, 75% 2x" },
      { label: "ab 50% 1x, 75% 2x, 90% 3x", value: "ab 50% 1x, 75% 2x, 90% 3x" },
    ],
    gliedertaxe: [
      { label: "Verbessert", value: "Verbessert" },
      { label: "Standard", value: "Standard" },
    ],
  };
  errormessage = "Pflichtfeld!";
  isSaved = false;
  isClosingModal = false;

  requiredControls: string[];
  requiredControlsUnfall: string[];

  dropDownEntries = {
    hausratZweitwohnung: {
      initialEntries: [
        { label: "bis 10.000 €", value: VersicherungssummeZweitwohnungEnum.VERSICHERUNGSSUMME_ZWEITWOHNUNG_10000 },
        { label: "bis 15.000 €", value: VersicherungssummeZweitwohnungEnum.VERSICHERUNGSSUMME_ZWEITWOHNUNG_15000 },
        { label: "bis 20.000 €", value: VersicherungssummeZweitwohnungEnum.VERSICHERUNGSSUMME_ZWEITWOHNUNG_20000 },
        { label: "bis 25.000 €", value: VersicherungssummeZweitwohnungEnum.VERSICHERUNGSSUMME_ZWEITWOHNUNG_25000 },
        { label: "bis 30.000 €", value: VersicherungssummeZweitwohnungEnum.VERSICHERUNGSSUMME_ZWEITWOHNUNG_30000 },
      ],
      visibleEntries: [],
    },
    hausratSeniorenheim: {
      initialEntries: [
        { label: "bis 10.000 €", value: VersicherungssummeSeniorenheimEnum.VERSICHERUNGSSUMME_SENIORENHEIM_10000 },
        { label: "bis 15.000 €", value: VersicherungssummeSeniorenheimEnum.VERSICHERUNGSSUMME_SENIORENHEIM_15000 },
        { label: "bis 20.000 €", value: VersicherungssummeSeniorenheimEnum.VERSICHERUNGSSUMME_SENIORENHEIM_20000 },
        { label: "bis 25.000 €", value: VersicherungssummeSeniorenheimEnum.VERSICHERUNGSSUMME_SENIORENHEIM_25000 },
        { label: "bis 30.000 €", value: VersicherungssummeSeniorenheimEnum.VERSICHERUNGSSUMME_SENIORENHEIM_30000 },
      ],
      visibleEntries: [],
    },
    freizeitPlusVersicherung: {
      initialEntries: [
        { label: "1.000 €", value: VersicherungssummeFreizeitPlusEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS_1000 },
        { label: "2.000 €", value: VersicherungssummeFreizeitPlusEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS_2000 },
        { label: "3.000 €", value: VersicherungssummeFreizeitPlusEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS_3000 },
        { label: "4.000 €", value: VersicherungssummeFreizeitPlusEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS_4000 },
      ],
      visibleEntries: [],
    },
    reisegepaeck: {
      initialEntries: [
        { label: "2.000 €", value: VersicherungssummeReisegepaeckEnum.VERSICHERUNGSSUMME_REISEGEPAECK_2000 },
        { label: "3.000 €", value: VersicherungssummeReisegepaeckEnum.VERSICHERUNGSSUMME_REISEGEPAECK_3000 },
        { label: "4.000 €", value: VersicherungssummeReisegepaeckEnum.VERSICHERUNGSSUMME_REISEGEPAECK_4000 },
        { label: "5.000 €", value: VersicherungssummeReisegepaeckEnum.VERSICHERUNGSSUMME_REISEGEPAECK_5000 },
        { label: "6.000 €", value: VersicherungssummeReisegepaeckEnum.VERSICHERUNGSSUMME_REISEGEPAECK_6000 },
      ],
      visibleEntries: [],
    },
    reiseruecktritt: {
      initialEntries: [
        { label: "2.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_2000 },
        { label: "3.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_3000 },
        { label: "4.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_4000 },
        { label: "5.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_5000 },
        { label: "6.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_6000 },
        { label: "7.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_7000 },
        { label: "8.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_8000 },
        { label: "9.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_9000 },
        { label: "10.000 €", value: VersicherungssummeReiseruecktrittEnum.VERSICHERUNGSSUMME_REISERUECKTRITT_10000 },
      ],
      visibleEntries: [],
    },
    grundflaecheGlas: {
      initialEntries: [
        { label: "5 m²", value: GrundflaecheEnum.GRUNDFLAECHE_5 },
        { label: "10 m²", value: GrundflaecheEnum.GRUNDFLAECHE_10 },
        { label: "15 m²", value: GrundflaecheEnum.GRUNDFLAECHE_15 },
      ],
      visibleEntries: [],
    },
  };

  constructor(
    private router: Router,
    private vertragDetailsService: VertragdetailsService,
    private formsService: FormsService,
    private formBuilder: UntypedFormBuilder,
    private angebotBearbeitenService: AngebotBearbeitenService,
    private beitragErmittelnService: BeitragErmittelnService,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.vertragDetails = this.vertragDetailsService.getVertragsdetails();
    this.initForm();
    this.initDropDowns();
  }

  public navigateBack() {
    if (this.bearbeitenForm.dirty && this.bearbeitenForm.touched) {
      this.showFormAlert();
    } else {
      this.navigateToVertragDetails();
    }
  }

  public saveChanges() {
    this.isSaved = true;
    if (this.checkDropDownValidity()) {
      this.formsService.getAngebotBearbeitenForms().markAsTouched();
      this.formsService.getAngebotBearbeitenForms().patchValue({ ...this.bearbeitenForm.getRawValue() });
      (
        (
          this.formsService
            .getAngebotBearbeitenForms()
            .get(this.formControls.AENDERBAREVERTRAGSDATEN_UNFALL) as UntypedFormArray
        ).controls[this.selectedUnfallIndex] as UntypedFormGroup
      ).patchValue({ ...(this.unfallFormArray.controls[this.selectedUnfallIndex] as UntypedFormGroup).value });
      this.navigateToVertragDetails();
    } else {
      this.validatorAlert.visible = true;
    }
  }

  public resetChanges() {
    this.bearbeitenForm.reset();
    this.getUnfallTabelleValuesFromService();
    this.navigateToVertragDetails();
  }

  public openUnfallModal(unfallEntry: AenderbareVertragsdatenUnfall, index: number): void {
    this.selectedUnfallEntry = unfallEntry;
    this.selectedUnfallIndex = index;
    this.tableModal.visible = true;
    this.requiredControlsUnfall = this.formsService.getRequiredControls(
      this.unfallFormArray.at(index) as UntypedFormGroup,
    );
  }

  /**
   * Closes modal and save changes locally if all required fields are filled out
   */
  public closeModalAndSaveChanges() {
    if (this.unfallFormArray.at(this.selectedUnfallIndex).valid) {
      this.tableModal.visible = false;
      this.isClosingModal = false;
    } else {
      this.isClosingModal = true;
    }
  }

  // Close modal and discard local changes by patching value from form service
  public closeModalAndDiscardChanges() {
    this.getUnfallTabelleValuesFromService();
    this.tableModal.visible = false;
    this.isClosingModal = false;
    this.unfallRenteChanged(this.unfallFormArray.at(this.selectedUnfallIndex).get(FormcontrolsEnum.UNFALLRENTE).value);
    this.checkFormsAfterValueChange();
  }

  public setDropDownValidators(yesNoFormControlValue: string, dropDownFormControlName: string) {
    this.angebotBearbeitenService.setDropDownValidators(
      yesNoFormControlValue,
      dropDownFormControlName,
      this.bearbeitenForm,
    );
    this.updateRequiredControls();
  }

  public setUnfallRentenvarianteValidator() {
    this.angebotBearbeitenService.setUnfallRentenvarianteValidator(this.unfallFormArray, this.selectedUnfallIndex);
    this.updateRequiredControls();
  }

  public checkFormsAfterValueChange() {
    this.angebotBearbeitenService.setDisabledState(this.bearbeitenForm, this.unfallFormArray, this.vertragDetails);
    this.setAllDropDownValidators();
  }

  /**
   * Set uprounded Invaliditätsgrundsumme value in from when focusout or keypress event fires
   *
   * @param grundsumme - user input value
   */
  public grundsummeChanged(grundsumme: number) {
    this.unfallFormArray.controls[this.selectedUnfallIndex].patchValue({
      [FormcontrolsEnum.INVALIDITAETSGRUNDSUUME]: this.roundUp(grundsumme, 100),
    });
  }

  /**
   * Set uprounded Unfallrente value in from when focusout or keypress event fires
   *
   * @param unfallRente - user input value
   */
  public unfallRenteChanged(unfallRente: number) {
    this.angebotBearbeitenService.setUnfallRenteValidators(unfallRente, this.selectedUnfallIndex);
    this.unfallFormArray.controls[this.selectedUnfallIndex].patchValue({
      [FormcontrolsEnum.UNFALLRENTE]: this.roundUp(unfallRente, 10),
    });
    this.updateRequiredControls();
  }

  /**
   * Gives back an errormessage for Unfallrente Numberinput
   */
  public getErrorMessageForUnfallRente(): string {
    const unfallRenteVertragsdetails =
      this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[this.selectedUnfallIndex].unfallRente;
    const minUnfallRente = this.formsService.getUnfallRente(unfallRenteVertragsdetails);
    const currentUnfallRente = this.unfallFormArray
      .at(this.selectedUnfallIndex)
      .get(FormcontrolsEnum.UNFALLRENTE).value;

    if (
      unfallRenteVertragsdetails !== null &&
      unfallRenteVertragsdetails !== 0 &&
      (currentUnfallRente === null || currentUnfallRente === 0)
    ) {
      return "";
    } else if (
      unfallRenteVertragsdetails !== null &&
      unfallRenteVertragsdetails !== 0 &&
      currentUnfallRente < minUnfallRente &&
      minUnfallRente > UnfallRenteMinMaxEnum.MIN
    ) {
      return this.getErrorMessageBestandsvertrag();
    } else {
      return this.getErrorMessageStandard(
        minUnfallRente === null || minUnfallRente < UnfallRenteMinMaxEnum.MIN
          ? UnfallRenteMinMaxEnum.MIN
          : minUnfallRente,
        UnfallRenteMinMaxEnum.MAX,
      );
    }
  }

  /**
   * Gives back an errormesage for Invaliditätsgrundsumme Numberinput.
   */
  public getErrorMessageForInvaliditaetsgrundsumme(versichertePerson: VersichertePerson): string {
    const [progression, gfg] = this.beitragErmittelnService.getProgressionAndGfgForPerson(versichertePerson);
    const grundsummeBestandsvertrag =
      this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[this.selectedUnfallIndex].invaliditaetsGrundsumme;
    const currentInvaliditaetsGrundsumme = this.unfallFormArray
      .at(this.selectedUnfallIndex)
      .get(FormcontrolsEnum.INVALIDITAETSGRUNDSUUME).value;
    const minGrundsumme = this.formsService.getInvaliditaetsGrundsumme(grundsummeBestandsvertrag, progression);

    if (currentInvaliditaetsGrundsumme === null || currentInvaliditaetsGrundsumme === 0) {
      return "";
    } else if (
      currentInvaliditaetsGrundsumme > 0 &&
      currentInvaliditaetsGrundsumme < minGrundsumme &&
      minGrundsumme > this.angebotBearbeitenService.getGrundsummeMin(progression)
    ) {
      return this.getErrorMessageBestandsvertrag();
    } else {
      return this.getErrorMessageStandard(
        minGrundsumme,
        this.angebotBearbeitenService.getGrundsummeMax(progression, gfg),
      );
    }
  }

  public isFormControlRequired(controlName: string): boolean {
    return this.requiredControls.includes(controlName) || this.requiredControlsUnfall?.includes(controlName);
  }

  checkUnfallTabelleVisibility(): boolean {
    let show = false;
    this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall.forEach((unfallEntry) => {
      show = unfallEntry?.versichertePerson !== null;
    });

    return show;
  }

  public openLeistungsuebersicht(leistungsuebersichtModal: DsModal) {
    leistungsuebersichtModal.visible = true;
  }

  /**
   * Function to show Rentenvariantevalue if enabled and not empty
   */
  public showUnfallRentenvarinateValue(index: number): string {
    return this.unfallFormArray.controls[index].get(FormcontrolsEnum.UNFALLRENTENVARIANTE).value !== null &&
      this.unfallFormArray.controls[index].get(FormcontrolsEnum.UNFALLRENTE).value > 0
      ? this.unfallFormArray.controls[index].get(FormcontrolsEnum.UNFALLRENTENVARIANTE).value
      : "";
  }

  private getUnfallTabelleValuesFromService() {
    (this.unfallFormArray.controls[this.selectedUnfallIndex] as UntypedFormGroup).setValue({
      ...(
        this.formsService
          .getAngebotBearbeitenForms()
          .get(this.formControls.AENDERBAREVERTRAGSDATEN_UNFALL) as UntypedFormArray
      ).controls[this.selectedUnfallIndex].value,
    });
  }

  /**
   * Standard errormessage text
   */
  private getErrorMessageStandard(minValue: number, maxValue: number): string {
    return `Der Wert muss zwischen ${minValue} € und ${maxValue} € liegen.`;
  }

  /**
   * Errormessage in case Bestandsvertragversicherungssumme exceeds the minimum Versicherungssumme
   */
  private getErrorMessageBestandsvertrag(): string {
    return (
      "Die Versicherungssumme darf die bereits im Vorvertrag vereinbarte Versicherungssumme" +
      " nicht unterschreiten. Bitte überprüfen Sie Ihre Eingabe."
    );
  }

  /**
   * Function to roundup values depending on icrement
   *
   * @param value - input value to round up
   * @param increment (Example: 10)
   */
  private roundUp(value: number, increment: number): number {
    return Math.ceil(value / increment) * increment;
  }

  private initVersicherungssummeDropDown(
    versicherungssumme: string,
    dropDownEntries: DropDownEntry[],
  ): DropDownEntry[] {
    let index = dropDownEntries.findIndex((element: DropDownEntry) => element.value === versicherungssumme);
    if (index === -1) {
      index = 0;
    }
    return dropDownEntries.filter((entry, i) => {
      if (i >= index) {
        return entry.value;
      }
    });
  }

  private initDropDowns() {
    const vertrag = this.vertragDetails.vertrag;
    this.dropDownEntries.hausratZweitwohnung.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenHausrat?.versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude,
      this.dropDownEntries.hausratZweitwohnung.initialEntries,
    );
    this.dropDownEntries.hausratSeniorenheim.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenHausrat?.versicherungssummeHausratAngehoerigeSeniorenheim,
      this.dropDownEntries.hausratSeniorenheim.initialEntries,
    );
    this.dropDownEntries.freizeitPlusVersicherung.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenHausrat?.versicherungssummeFreizeitPlus,
      this.dropDownEntries.freizeitPlusVersicherung.initialEntries,
    );
    this.dropDownEntries.reisegepaeck.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenReise?.versicherungssummeReisegepaeck,
      this.dropDownEntries.reisegepaeck.initialEntries,
    );
    this.dropDownEntries.reiseruecktritt.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenReise?.versicherungssummeReiseruecktritt,
      this.dropDownEntries.reiseruecktritt.initialEntries,
    );
    this.dropDownEntries.grundflaecheGlas.visibleEntries = this.initVersicherungssummeDropDown(
      vertrag.aenderbareVertragsdatenGlas?.grundflaeche,
      this.dropDownEntries.grundflaecheGlas.initialEntries,
    );
  }

  private setAllDropDownValidators() {
    const versicherungssummeFormControlPairs = [
      [FormcontrolsEnum.REISERUECKTRITT, FormcontrolsEnum.VERSICHERUNGSSUMME_REISERUECKTRITT],
      [FormcontrolsEnum.REISEGEPAECK, FormcontrolsEnum.VERSICHERUNGSSUMME_REISEGEPAECK],
      [FormcontrolsEnum.FREIZEIT_PLUS, FormcontrolsEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS],
      [FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN, FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE],
      [FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG, FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG],
    ];

    for (const formControlPair of versicherungssummeFormControlPairs) {
      const formControlYesNo = this.bearbeitenForm.get(formControlPair[0]);
      if (formControlYesNo && formControlYesNo.enabled) {
        this.setDropDownValidators(formControlYesNo.value, formControlPair[1]);
      }
    }
  }

  private initForm() {
    const angebotBearbeitenForm = this.formsService.getAngebotBearbeitenForms();
    this.bearbeitenForm = this.formsService.buildAngebotBearbeitenForm();

    (angebotBearbeitenForm.get(this.formControls.AENDERBAREVERTRAGSDATEN_UNFALL) as UntypedFormArray).controls.forEach(
      (formGroup, index) => {
        (this.bearbeitenForm.get(this.formControls.AENDERBAREVERTRAGSDATEN_UNFALL) as UntypedFormArray).push(
          this.formBuilder.group({
            [FormcontrolsEnum.INVALIDITAETSGRUNDSUUME]: [
              "",
              this.angebotBearbeitenService.setInvaliditaetsgrundsummeValidator(
                formGroup as UntypedFormGroup,
                this.vertragDetails,
                index,
              ),
            ],
            [FormcontrolsEnum.UNFALLRENTE]: [
              "",
              this.angebotBearbeitenService.setInitialUnfallRenteAndUnfallRentenvarianteValidator(
                this.vertragDetails,
                index,
              ),
            ],
            [FormcontrolsEnum.UNFALLRENTENVARIANTE]: [
              "",
              this.angebotBearbeitenService.setInitialUnfallRenteAndUnfallRentenvarianteValidator(
                this.vertragDetails,
                index,
              ),
            ],
            [FormcontrolsEnum.GLIEDERTAXE]: [],
          }),
        );
      },
    );

    this.bearbeitenForm.patchValue({ ...angebotBearbeitenForm.value });
    this.unfallFormArray = this.bearbeitenForm.controls.aenderbareVertragsdatenUnfall as UntypedFormArray;
    this.angebotBearbeitenService.setDisabledState(this.bearbeitenForm, this.unfallFormArray, this.vertragDetails);

    Object.entries(this.bearbeitenForm.controls).forEach((key) => {
      if (angebotBearbeitenForm.get(key[0]).disabled) {
        key[1].disable();
        key[1].patchValue(angebotBearbeitenForm.get(key[0]).value);
      }
    });
    this.updateRequiredControls();
  }

  private showFormAlert() {
    this.formAlert.visible = true;
  }

  private updateRequiredControls() {
    this.requiredControls = this.formsService.getRequiredControls(this.bearbeitenForm);
  }

  private navigateToVertragDetails() {
    this.router.navigate(["detailinformationen", { vertrag: this.vertragDetailsService.getVertragsnummer() }]);
  }

  private checkDropDownValidity(): boolean {
    const versicherungssummeFormControlPairs = [
      [FormcontrolsEnum.REISERUECKTRITT, FormcontrolsEnum.VERSICHERUNGSSUMME_REISERUECKTRITT],
      [FormcontrolsEnum.REISEGEPAECK, FormcontrolsEnum.VERSICHERUNGSSUMME_REISEGEPAECK],
      [FormcontrolsEnum.FREIZEIT_PLUS, FormcontrolsEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS],
      [FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN, FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE],
      [FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG, FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG],
    ];

    let isDropDownValid = true;
    versicherungssummeFormControlPairs.forEach((dropDownControl) => {
      if (
        this.bearbeitenForm.get(dropDownControl[0]).enabled &&
        this.bearbeitenForm.get(dropDownControl[1]).errors !== null
      ) {
        isDropDownValid = false;
      }
    });
    return isDropDownValid;
  }
}

export interface DropDownEntry {
  label: string;
  value: string;
}
